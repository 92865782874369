img.tokenImage {
  width: 30px;
  height: 30px;
  position: absolute;
  margin-top: -7px;
}

span.tokenName {
  margin-left: 40px;
  color: #fff;
  text-decoration: none;
}

.tokenLink {
  text-decoration: underline dashed;
  color: #fff;
}

td, th {
  border-bottom: 1px solid rgb(38 38 38)!important;
}

.tokenContent {
  padding: 0 30px;
}

.tokenContent h4 {
  margin: 0!important;
  padding: 0!important;
}

.tokenDescription {
  border-left: 1px solid gray;
  padding-left: 30px;
}

.tokenDescription a {
  color: #fff!important;
}

.tokenDescription span {
  font-weight: bold;
}